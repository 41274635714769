.mobile-monitor {
  text-align: center;
  line-height: 0.533333rem;
  padding: 0 0.266667rem 0 0.266667rem;
}

.mobile-monitor .mobile-header {
  background: #8996b0;
  color: #ffffff;
  padding: 0.266667rem 0.133333rem;
  border-radius: 0.133333rem;
  margin: auto;
}

.mobile-monitor img {
  width: 6.933333rem;
  height: 6.933333rem;
  object-fit: contain;
}

.mobile-monitor .height-17 {
  height: 0.453333rem;
}

.mobile-monitor .height-28 {
  height: 0.746667rem;
}

.mobile-monitor .skip-btn {
  color: #1989fa;
  cursor: pointer;
}